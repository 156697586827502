<template>
   <div class="w-full pt-2">

        <!-- Image Section -->
        <div class="w-full">
            <Link :href="article.internal_url">
                <VLazyImage
                    :src="cdnUrl('jpg')"
                    :srcset="cdnUrl('webp') + ', ' + cdnUrl('jpg')"
                    :src-placeholder="placeholder"
                    class="w-full"
                    :alt="article.title"
                />
            </Link>
        </div>

          <!-- Date and Newspaper Section -->
        <div class="w-full flex align-items-center mt-2">
            <span class="text-600 text-sm font-medium">{{ date(article.publication_date) }}</span>
            <span class="text-600 font-medium text-sm mx-3">|</span>
            <Link :href="route('newsportal.newspaper', newspaper.slug)">
                <span class="bg-blue-800 text-white text-sm block py-1 px-2 border-round mr-3">{{ article.newspaper.name }}</span>
            </Link>
        </div>

        <!-- Title Section -->
        <div class="w-full">
            <Link :href="article.internal_url">
                <h2 class="text-900 font-bold text-xl mt-2" v-html="title"></h2>
            </Link>
        </div>

    </div>

</template>

<script>
import {Link} from '@inertiajs/vue3'
import moment from "moment-timezone";
import VLazyImage from 'v-lazy-image';
import placeholder from '/resources/images/cyprusnews/placeholder.png'

export default {
    components : {
        Link,
        VLazyImage,
    },
    props      : {
        newspaper : {
            type     : Object,
            required : false,
        },
        article   : Object,
        type      : String,
    },
    data       : () => {
        return {
            placeholder: placeholder,
            thumbnailsPath : '/resources/images/thumbnail/'
        }
    },
    methods    : {
        date(date) {
            return moment(date).format("D MMM YYYY, H:mm");
        },
        cdnUrl(type) {
            if (this.$page.props.env !== 'production') {
                return `${this.thumbnailsPath}${this.article.id}.jpg`;
            }

            const imgUrl = btoa(`https://cyprusnews.live${this.thumbnailsPath}${this.article.id}.jpg`);
            return `https://cyprusnews.live/cdn?path=${imgUrl}&width=573&height=323&format=${type}`;
        }
    },
    mounted() {
    },
    computed : {
        title() {
            return this.article.title.replace(/(?<!&)(#[0-9]+;)/g, "&$1");
        },
        categoriesOptions() {
            if (this.article.categories) return this.article.categories;
            return [];
        },
    },
};
</script>

<style scoped>
img {
    aspect-ratio : 320 / 180;
}
</style>
